.portfolio-performance-table {
  height: calc(100vh - 330px);
  overflow: auto;
  table {
    border: 1px solid lightgray;
    font-size: 14px;
    background: white;
    border-spacing: 0ch;
    width: 100%;
  }

  tr {
    height: 30px;
  }

  tbody {
    border-bottom: 1px solid lightgray;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }
  th {
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
    padding: 2px 11px;
    background: #fafaf9;
    position: relative;
  }

  td {
    background: #fff;
    padding: 2px 11px;
    border-left: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
  .sticky-col1 {
    position: sticky;
    left: 0;
    z-index: 1;
  }
  .sticky-col2 {
    border-right: 2px solid lightgray;
    position: sticky;
    left: 80px;
    z-index: 1;
  }
  td.total {
    border-top: 2px solid lightgray;
    background: #f3f2f2;
  }
  tfoot {
    color: gray;
  }

  tfoot th {
    font-weight: normal;
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 21;
  }

  .link {
    cursor: pointer;
    color: #006dcc;
  }
  .link:hover {
    text-decoration: underline;
  }

  /* Tooltip container */
  .tooltip {
    position: relative;
    display: inline-block;
  }

  /* Tooltip text */
  .tooltip .tooltip-text {
    top: -5px;
    left: 110%;
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    max-width: none;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltip-text {
    visibility: visible;
  }
  .tooltip .tooltip-text::after {
    content: ' ';
    position: absolute;
    top: 50%;
    right: 100%; /* To the left of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
  .cell {
    display: flex;
  }
  .cell-metric-value {
    width: 100%;
    text-align: right;
  }
}
