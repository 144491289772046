.vendor-dashboard-organism {
  background: #fff;
  padding: 0.75rem;
  .vendor-dashboard-organism-spinner {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.loading-container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning-message-container {
  background: #fff;
  padding: 0.75rem;
}
