.kpi-card {
  background-color: white;
  justify-content: baseline;
  min-width: 200px;
  border: 1px solid #f0f0f0;
  padding: 8px 12px 8px 12px;
  box-shadow: 0px 1px 4px 0px #00000040;
  border-radius: 4px;
  .card-text {
    font-weight: 510;
    font-size: 12px;
  }
  .arrow {
    align-self: center;
    font-size: 14px;
  }
  .prev-interval {
    font-size: 12px;
    color: #b0adab;
  }
  .kpi-value {
    font-size: 20px;
  }
  .sub-kpis {
    justify-content: space-between;
    width: 100%;
  }
}
