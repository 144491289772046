.metrics-bar-chart {
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: baseline;
  border: 1px solid #f0f0f0;
  padding: 8px 12px 8px 12px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  .card-text {
    font-weight: 400;
    font-size: 14px;
  }
  .arrow {
    align-self: center;
    font-size: 14px;
  }
  .kpi-value {
    font-size: 24px;
    font-weight: 400;
  }
  .kpi {
    display: flex;
  }
  .sub-kpis {
    column-gap: 5px;
    display: flex;
    width: 100%;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .chart{
    width: 17vw;
  }
}
