.portfolio-performance {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #f5f7fa;
  .tab-container {
    display: flex;
    align-items: center;
    font-size: 0.813rem;
    font-weight: 400;
    background-color: white;
    justify-content: space-between;

    .left-items {
      display: flex;
    }

    .right-items {
      display: flex;
      align-items: center;
      padding: 8px;
    }
  }
  .container {
    width: 100%;
  }

  .dropdown {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    display: flex;
    justify-content: space-between;
  }
  .ant-btn:hover {
    color: rgb(0, 0, 0) !important;
    border-color: #1b96ff !important;
    background: #fff;
  }
  .ant-btn:focus {
    color: rgb(0, 0, 0) !important;
    border-color: #1b96ff !important;
    background: #fff;
  }
  .ant-dropdown-menu {
    border-radius: 0.25rem;
    border: 1px solid #e5e5e5;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  }
  .ant-dropdown {
    top: 45px !important;
  }

  .tab-text {
    cursor: pointer;
    background-color: white;
    border: none;
    padding: 10px 12px 10px 12px;
    gap: 8px;
  }
  .selected-tab {
    font-weight: 700;
    border-bottom: #1b96ff;
    border-style: solid;
    border-width: 0 0 2px 0;
  }
  .reset-button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #1589ee;
    &:disabled {
      color: #dddbda;
    }
  }
}
.rmsc {
  --rmsc-main: #4096ff !important;
  --rmsc-hover: #f5f5f5 !important;
  --rmsc-selected: #e2e6ea !important;
  --rmsc-border: #d9d9d9 !important;
  --rmsc-gray: black !important;
  --rmsc-bg: #fff !important;
  --rmsc-p: 15px !important; /* Spacing */
  --rmsc-radius: 4px !important; /* Radius */
  --rmsc-h: 30px !important; /* Height */
  font-size: 14px !important;
}
.rmsc .dropdown-content {
  width: max-content !important;
  z-index: 1000 !important;
}
.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
}

.rmsc .dropdown-container:hover {
  border-color: #4096ff !important;
}

.rmsc .dropdown-heading:hover {
  cursor: pointer !important;
}
