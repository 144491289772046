.date-range-modal-molecule {
  .ant-modal-content {
    padding: 0;
    border-radius: 8px;
    .ant-modal-header {
      text-align: center;
      padding: 14px 24px;
      border-bottom: 1px solid #bfbfbfc4;
    }

    .ant-modal-footer {
      background-color: #f3f3f3;
      border-top: 1px solid #bfbfbfc4;
      padding: 10px 16px;
      text-align: right;
      border-radius: inherit;
    }
    .ant-modal-body {
      padding: 1rem;
    }
    .ant-btn,
    .ant-btn-primary {
      border: 0;
      border-radius: 0.25rem;
    }

    .ant-btn-primary:disabled,
    .ant-btn-primary[disabled] {
      background-color: #c9c7c5;
      color: #ffffff;
    }
    .ant-btn-primary {
      background-color: #0076d3;
    }
    .ant-btn-default {
      color: #0076d3;
      border: 1px solid #bfbfbfc4;
    }
    .ant-picker {
      border-radius: 0.25rem;
    }
  }
}
.ant-picker-dropdown {
  top: 0% !important;
}
