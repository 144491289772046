.portfolio-overview-organism {
  display: block;
  .portfolio-overview-organism-spinner {
    position: fixed;
    width: 100%;
    height: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.loading-container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.warning-message-container {
  padding: 0.75rem;
}
