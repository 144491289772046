.table-col-header {
  .resizer.isResizing {
    opacity: 1;
  }

  @media (hover: hover) {
    .resizer {
      opacity: 0;
    }

    *:hover > .resizer {
      opacity: 1;
    }
  }
  .resizer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 5px;
    cursor: col-resize;
    user-select: none;
    touch-action: none;
  }
  .cursor-pointer {
    cursor: pointer;
    text-align: start;
    text-wrap: nowrap;
  }
  .sort {
    padding-left: 5px;
    visibility: hidden;
  }
  .cursor-pointer:hover .sort {
    visibility: visible;
  }
}
