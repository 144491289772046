.vendor-dashboard-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-title {
    line-height: 1.25;
    font-weight: 700;
    font-size: 1.125rem;
    width: 100%;
    color: rgb(8, 7, 7);
  }

  &-container {
    width: 100%;
  }

  &-dropdown {
    margin: 0;
    border-radius: 0.25rem;
  }
  .ant-btn:hover {
    color: rgb(0, 0, 0);
    border-color: #d9d9d9;
    background: #fff;
  }
  .ant-btn:focus {
    color: rgb(0, 0, 0);
    border-color: #1b96ff;
    background: #fff;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #080707 !important;
    font-weight: 600;
    text-shadow: none;
  }
  .ant-tabs-ink-bar {
    position: absolute;
    background: #1589ee;
    pointer-events: none;
  }
}
div[class*='ant-dropdown-menu-submenu'] {
  top: 0 !important;
}
.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 125px;
}
